import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "gatsby-image/withIEPolyfill"
import BackgroundImage from "gatsby-background-image"
import { graphql } from "gatsby"
import SobreNos from "../components/sobre-nos"
import AutorizadasList from "../components/autorizadas-list"

const IndexPage = ({ data }) => {
  return (
    <Layout showHero>
      <SEO title="Assistência Técnica Eletrodomésticos Porto Alegre | Cedilar" />
      <div className="px-4 md:px-0 lg:flex my-4 container mx-auto justify-between">
        <div className="my-6 lg:w-2/3">
          <h1 className="text-2xl font-bold pb-4">
            Assistência Técnica de Aparelhos Eletrodomésticos Cedilar Porto
            Alegre.
          </h1>
          <p className="pb-4">
            Consertamos uma grande variedade de aparelhos que você utiliza no
            seu dia a dia. Navegue nosso site para tirar dúvidas sobre seu
            produto ou entre em contato conosco para clarificar informações
            necessárias.
          </p>
          <p className="pb-4">
            Visite nossa página CONTATO para acessar o mapa do Google e buscar
            direções até nosso estabelecimento.
          </p>
          <p className="mb-2">
            Recomendamos que visite a página de PERGUNTAS FREQUENTES para
            clarificar dúvidas simples sobre seu produto.
          </p>
        </div>
        <div className="lg:w-1/3">
          <Image
            className="lazyload"
            fluid={data?.eletrodomesticosCozinha?.childImageSharp?.fluid}
            alt="Conserto de Aparelhos Eletrodomésticos"
          />
        </div>
      </div>
      <SobreNos />
      <AutorizadasList />
      <div className="bg-gray-200">
        <div className="px-4 md:px-0 my-4 container mx-auto">
          <h2 className="pb-2 pt-12 text-xl font-bold">Conheça a Cedilar</h2>
          <p className="pb-6">
            Com as grandes mudanças de temperatura e estações que atingem de
            forma direta o morador de Porto Alegre, fica bem difícil não passar
            por momentos onde algum equipamento da sua casa não sofra as
            consequências, acabando por precisar de bons reparos urgentes. Mas,
            você tem em quem confiar de verdade para te ajudar?
          </p>
          <p className="pb-6">
            A CeDilar vem com esse objetivo, ajudar você a ter o melhor em
            assistência técnica, seja no seu chuveiro ou torneira que precise de
            água quente para amenizar o frio, ou ainda na limpeza e manutenção
            do seu eletrodoméstico, a nossa intenção sempre será ofertar
            excelência em nossos serviços.
          </p>
          <p className="pb-12">
            A Assistência Técnica CeDilar trabalha há 30 anos no mercado
            oferecendo o que há de melhor em seus serviços, com uma equipe
            preparada, especializa e experiente. Quer saber tudo sobre nós e
            como podemos te ajudar? Então, vamos conhecer!
          </p>
        </div>
      </div>
      <BackgroundImage
        Tag="section"
        fluid={data?.parqueFarroupilha?.childImageSharp?.fluid}
        className="bg-scroll h-64"
        alt="Parque Farroupilha Porto Alegre"
      />
      <div className="px-4 md:px-0 container mx-auto">
        <h2 className="pb-2 pt-12 text-xl font-bold">
          Reparo De Diversos Aparelhos Fora da Garantia
        </h2>
        <p className="pb-6">
          Se seu aparelho está fora da garantia, nós também podemos te ajudar.
          Entre em contato para que possamos providenciar um orçamento.
        </p>
        <h2 className="pb-2 text-xl font-bold">
          Reparo de Aparelhos Autorizados Por Grandes Marcas
        </h2>
        <p className="pb-6">
          Como já dito, nosso trabalho é autorizado pelas melhores marcas de
          eletrodomésticos e equipamentos do mercado, sendo assim, você pode ter
          a segurança de, ao mandar seus produtos para nossa assistência, ter a
          manutenção da garantia de fábrica e ainda de reposição de peças
          originais, trabalhando com o que há de melhor.
        </p>
        <p className="pb-12">
          As marcas que atuam de forma parceira em questão de autorização para
          desenvolvimento de atividades de assistência são Mondial, Cardal,
          Sintex, Lorenzetti, Hydra Corona e ainda Philips Walita. Todas confiam
          em nosso trabalho, devido anos de experiência, e garantem a máxima
          qualidade no serviço ofertado pela nossa empresa.
        </p>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    eletrodomesticosCozinha: file(
      relativePath: { eq: "Eletrodomesticos-Cozinha.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    parqueFarroupilha: file(
      relativePath: { eq: "Parque-Farroupilha-Porto-Alegre.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

export default IndexPage
